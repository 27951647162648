import React, { memo } from 'react';
import Layout from '../components/Layout';

const ErrorPage = memo(() => (
  <Layout fullMenu>
    <article id="main">
      <header>
        <h2>Not Found</h2>
        <p>Not a Valid URL</p>
      </header>
    </article>
  </Layout>
));

export default ErrorPage;
